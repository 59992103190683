import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      cashbackComSeuCartao: imageSharp(fluid: { originalName: { regex: "/cashback-com-seu-cartao/" }}) {
        fluid(maxWidth: 784, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      cartaoDeCredito: imageSharp(fluid: { originalName: { regex: "/cartao-de-credito/" }}) {
        fluid(maxWidth: 361, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      cashbackInterShop: imageSharp(fluid: { originalName: { regex: "/cashback-no-intershop/" }}) {
        fluid(maxWidth: 552, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      invistaGanhe: imageSharp(fluid: { originalName: { regex: "/invista-e-ganhe-cashback/" }}) {
        fluid(maxWidth: 552, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      invistaEmFundos: imageSharp(fluid: { originalName: { regex: "/invista-em-fundos/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      cashbackNoCartao: imageSharp(fluid: { originalName: { regex: "/cashback-cartao/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      dinheiroDeVolta: imageSharp(fluid: { originalName: { regex: "/dinheiro-de-volta/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      comprandoNoInterShop: imageSharp(fluid: { originalName: { regex: "/comprando-no-intershop/" }}) {
        fluid(maxWidth: 310, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      fundosInterInvest: imageSharp(fluid: { originalName: { regex: "/fundos-inter-invest/" }}) {
        fluid(maxWidth: 310, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      gistCards: imageSharp(fluid: { originalName: { regex: "/gift-cards/" }}) {
        fluid(maxWidth: 310, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      shellBox: imageSharp(fluid: { originalName: { regex: "/shellbox/" }}) {
        fluid(maxWidth: 310, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      intercel: imageSharp(fluid: { originalName: { regex: "/intercel-carousel/" }}) {
        fluid(maxWidth: 361, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      rotativo: imageSharp(fluid: { originalName: { regex: "/rotativo/" }}) {
        fluid(maxWidth: 310, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      interTravel: imageSharp(fluid: { originalName: { regex: "/inter-travel/" }}) {
        fluid(maxWidth: 310, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      acompanheSeuCashback: imageSharp(fluid: { originalName: { regex: "/acompanhe-seu-cashback/" }}) {
        fluid(maxWidth: 394, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      logosInterShop: imageSharp(fluid: { originalName: { regex: "/lojasInterShop/" }}) {
        fluid(maxWidth: 363, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
