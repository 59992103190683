import React, { useState } from 'react'

import useWidth from 'src/hooks/window/useWidth'
import Help from 'inter-frontend-svgs/lib/orangeds/MD/help'

import { orange } from 'src/styles/colors'
import { Tooltip, ButtonToolTip } from './style'

const ToolTip = () => {
  const [ open, setOpen ] = useState(false)

  const WIDTH_LG = 1024
  const windowWidth = useWidth(300)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      { windowWidth >= WIDTH_LG
        ? (
          <ButtonToolTip onClick={() => handleClick()}>
            <Help color={orange.extra} width={16} height={16} />
          </ButtonToolTip>
          )
        : (
          <ButtonToolTip onClick={() => setOpen(true)}>
            <Help color={orange.extra} width={16} height={16} />
          </ButtonToolTip>)
      }

      {open &&
        <Tooltip className='tooltip'>
          <p className='fs-12 lh-15 fw-700 text-white mb-2'>Cashback em Pontos Loop nos Fundos de Investimento</p>
          <p className='fs-12 lh-15 fw-400 text-white text-left'>
            Clientes Black, WIN ou que participam de uma Comunidade de Investimentos de nível 7 podem ganhar Pontos Loop ao
            investir em fundos com selo de cashback. O Inter te devolve 40% (para clientes WIN ou comunidades nível 7)
            e 20% (para clientes Black) da receita de distribuição líquida de impostos, desde que a soma de todos
            os seus potenciais cashbacks de fundos seja superior a R$ 10,00. O valor calculado será pago em
            Pontos Loop, considerando uma conversão de R$ 0,025 por ponto devido.
          </p>
          <span
            title='Entendi'
            className='d-block fs-12 lh-15 fw-700 text-orange--extra text-right cursor-pointer'
            onClick={() => setOpen(false)}
          >Entendi
          </span>
        </Tooltip>
      }
    </>
  )
}

export default ToolTip
