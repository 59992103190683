import React, { useState, useEffect, useRef, useMemo } from 'react'
import Img from 'gatsby-image'
import { animated, useTrail } from 'react-spring'
import { useStaticQuery, graphql } from 'gatsby'
import useDomReady from 'src/hooks/window/useDomReady'

import { Modal } from 'src/components/Modal'
import Layout from 'src/layouts/BaseLayout'
// import ScrollTo from 'src/components/ScrollTo'
import OrangeIcon from '../../components/UI/MarkdownIcon/OrangeDsIcon'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import Faq from 'src/components/Faq/index'
import CashbackSimulator from './components/CashbackSimulator/_index'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ToolTip from './ToolTip/_ToolTip'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'

import WhatIsCashbackJSON from './assets/data/WhatIsCashback.json'
import BlogArticlesCashbacksJSON from './assets/data/BlogArticlesCashbacks.json'
import WhereToEarnCashbackJSON from './assets/data/WhereToEarnCashback.json'
// import cashbackJSON from 'src/assets/data/cashback/cashback.json'

import useWidthResize from 'src/hooks/window/useWidth'
import useScroll from '../../hooks/window/useScroll'
import isVisible from '../../utils/isVisible'

import {
  Wrapper,
  ButtonArrow,
  WhatIsCashback,
  CashbackComCartao,
  ImgSize,
  ImgSizeCashbackInterShop,
  ImgSizeInvistaGanhe,
  BlogCarousel,
  PerguntasFrequentes,
  WhereToEarnCashbackSection,
  ImgSizeWhereToEarnCashback,
  VideoDiv,
  Video,
  LogosInterShop,
} from './style'

import imgOpacity from './assets/images/opacity.png'

import pageContext from './pageContext.json'
import usePageQuery from './pageQuery'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

const translateFrom = {
  config: {
    duration: 600,
  },
  opacity: 0,
  transform: 'translateY(30px)',
}

const translateTo = {
  opacity: 1,
  transform: 'translateY(0)',
}

type WhatIsCashbackProps = {
  icon: string;
  service: string;
  store: string;
  cashback: string;
  date: string;
}

type BlogArticlesCashbacksProps = {
  image: string;
  primaryTag: string;
  secundaryTag: string;
  title: string;
  description: string;
  link: string;
  alt: string;
  data: string;
}

type WhereToEarnCashbackProps = {
  title: string;
  description: string;
  cta: string;
  url: string;
  icon: string;
}

const Cashbacks = () => {
  const data = usePageQuery()
  const scroll = useScroll(300)
  const windowWidth = useWidthResize(1000)
  const isMobile = windowWidth <= 768
  const [ sendDatalayerEvent ] = useDataLayer()
  const domReady = useDomReady()
  const [ isOpen, setIsOpen ] = useState(false)

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_06',
    element_action: 'click scroll',
    element_name: 'Abrir minha conta no Inter',
    section_name: 'Invista e ganhe Cashback em Pontos Loop',
  })

  const openAccountRightForm = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <OpenAccountRightForm
        closeModal={() => setIsOpen(false)}
        formName='Formulario pagina cashback abertura de contas'
        dataLayer={dataLayer}
      />
    </Modal>
  )

  const openModalAndSendDatalayer = (dataLayer: IDataLayerParams) => {
    setDataLayer(dataLayer)
    sendDatalayerEvent(dataLayer)
    setIsOpen(true)
  }

  const videoElement = useMemo(() => (
    <>
      <Video
        key='introVideo'
        height='auto'
        width=''
        loop
        muted
        autoPlay
        className='video'
      >
        <source
          id='videoMp4'
          type='video/mp4'
          src='https://static.bancointer.com.br/videos/cashback/MP4/caminho-extrato-cashback.mp4'
        />
        <source
          id='videoWebm'
          type='video/webm'
          src='https://static.bancointer.com.br/videos/cashback/WEBM/caminho-extrato-cashback.webm'
        />
      </Video>
    </>
  ), [])

  // Refs
  const whereToEarnRef = useRef<HTMLElement>(null)
  const interShopRef = useRef<HTMLElement>(null)
  const interInvestRef = useRef<HTMLElement>(null)
  const cardRef = useRef<HTMLElement>(null)

  // Animations
  const [ animatedWhereToEarn, setAnimatedWhereToEarn ] = useTrail(2, () => (translateFrom))
  const [ animatedInterShop, setAnimatedInterShop ] = useTrail(2, () => (translateFrom))
  const [ animatedInvest, setAnimatedInvest ] = useTrail(2, () => (translateFrom))
  const [ animatedCard, setAnimatedCard ] = useTrail(2, () => (translateFrom))

  useEffect(() => {
    if (isVisible(whereToEarnRef.current, -0.3)) {
      setAnimatedWhereToEarn((translateTo))
    }
    if (isVisible(interShopRef.current, -0.3)) {
      setAnimatedInterShop((translateTo))
    }
    if (isVisible(interInvestRef.current, -0.3)) {
      setAnimatedInvest((translateTo))
    }
    if (isVisible(cardRef.current, -0.3)) {
      setAnimatedCard((translateTo))
    }
  }, [ scroll ])

  const [ imageIndex, setImageIndex ] = useState(0)
  const [ iconIndex, setIconIndex ] = useState(0)

  const imageNames = [
    'comprandoNoInterShop',
    'fundosInterInvest',
    'gistCards',
    'shellBox',
    'intercel',
    'rotativo',
    'interTravel',
  ]

  const images = useStaticQuery(graphql`
    query {
      comprandoNoInterShop: imageSharp(fluid: { originalName: { regex: "/comprando-no-intershop/" }}) {
        fluid(maxWidth: 310, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      usandoCartaoDeCredito: imageSharp(fluid: { originalName: { regex: "/usando-o-cartao-de-credito/" }}) {
        fluid(maxWidth: 310, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      fundosInterInvest: imageSharp(fluid: { originalName: { regex: "/fundos-inter-invest/" }}) {
        fluid(maxWidth: 310, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      gistCards: imageSharp(fluid: { originalName: { regex: "/gift-cards/" }}) {
        fluid(maxWidth: 310, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      shellBox: imageSharp(fluid: { originalName: { regex: "/shellbox/" }}) {
        fluid(maxWidth: 310, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      intercel: imageSharp(fluid: { originalName: { regex: "/intercel-carousel/" }}) {
        fluid(maxWidth: 310, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      rotativo: imageSharp(fluid: { originalName: { regex: "/rotativo/" }}) {
        fluid(maxWidth: 310, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      interTravel: imageSharp(fluid: { originalName: { regex: "/inter-travel/" }}) {
        fluid(maxWidth: 310, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  function changeImage (nextSlide: number) {
    setImageIndex(nextSlide)
    setIconIndex(nextSlide)
  }

  return (
    <Wrapper>
      {openAccountRightForm}
      <Layout pageContext={pageContext}>
        {/* <ContentHeader className='py-5 d-flex position-relative'>
          <div className='container'>
            <div className='row align-items-center text-center'>
              <div className='col-12 pb-5'>
                <h1 className='fs-24 fs-lg-32 text-white fw-300'>
                  O Inter já devolveu tudo isso de cashback:
                </h1>
                <p className='text-white returned-cashback mt-4 mt-md-2'>
                  <span className='dollar-sign'>R$ </span>{cashbackJSON.cashback}
                </p>
              </div>
              <ScrollTo
                section='dobra_01'
                sectionName='O Inter já devolveu tudo isso de cashback:'
                elementName='Saiba como ganhar também.'
                to='#compre-e-ganhe'
                styles='text-center'
              >
                <ScrollLink className='pb-md-4'>
                  <p className='text-white mb-0 fs-14 lh-22 fw-600'>Saiba como ganhar também.</p>
                  <div className='circle-chevron mt-2 d-flex align-items-center justify-content-center'>
                    <OrangeIcon
                      size='MD'
                      color='white'
                      icon='chevron-down'
                    />
                  </div>
                </ScrollLink>
              </ScrollTo>
            </div>
          </div>
        </ContentHeader> */}
        <section className='py-5'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-12 col-md-6 col-xl-5 order-md-last'>
                <span className='fs-12 fs-lg-14 lh-15 lh-lg-17 text-grayscale--300 text-uppercase fw-600'>Dinheiro de volta</span>
                <h2 className='fs-40 fs-lg-64 text-grayscale--500 fw-300'><span className='d-block'>O que é</span> cashback?</h2>
                <p className='fs-16 lh-19 text-grayscale--400'>
                  <span className='fw-600'>É dinheiro de volta.</span> Simples assim. No Inter você compra, investe e recebe parte do dinheiro de volta, direto na sua conta, para usar como quiser.
                </p>
              </div>
              <div className='col-12 col-md-6 col-xl-5 offset-xl-1'>
                {
                  WhatIsCashbackJSON.map((item: WhatIsCashbackProps) => (
                    <WhatIsCashback className='rounded-2 d-flex justify-content-between py-3 px-3 mb-3 ml-xl-0' key={item.service}>
                      <div className='d-flex justify-content-between'>
                        <div className='pr-3 d-flex align-items-center'>
                          <OrangeIcon
                            size='MD'
                            color='#FF9D42'
                            icon={item.icon}
                          />
                        </div>
                        <div className='d-flex flex-column'>
                          <span className='fs-10 lh-12 text-grayscale--300 mb-1'>{item.service}</span>
                          <span className='fs-14 lh-18 text-grayscale--500 fw-600'>{item.store}</span>
                        </div>
                      </div>
                      <div className='d-flex flex-column'>
                        <span className='fs-14 lh-18 text-green--dark text-right'>+R$ <span className='fw-600'>{item.cashback}</span></span>
                        <span className='fs-12 lh-16 text-grayscale--300 text-right'>{item.date}</span>
                      </div>
                    </WhatIsCashback>
                  ))
                }
              </div>
            </div>
          </div>
        </section>
        <section id='compre-e-ganhe' className='bg-grayscale--100'>
          <div className='container py-5'>
            <div className='row align-items-center justify-content-center'>
              <div className='col-12 col-md-6 col-xl-5 offset-xl-1'>
                <h2 className='fs-20 fs-md-32 fs-lg-40 lh-25 lh-md-40 lh-lg-50 lh-xl-54 text-grayscale--500 fw-300 mb-md-0'>
                  <span className='d-xl-block'>Cashback para todo </span>
                  <span className='d-md-block d-lg-inline'>mundo. Correntista,</span> ou não.
                </h2>
              </div>
              <div className='col-12 col-md-6 col-xl-5'>
                <p className='fs-16 lh-19 text-grayscale--500 fw-600'>Ganhe até <span className='text-green--dark'>20% de cashback</span> fazendo suas compras no Shopping do Inter.</p>
                <ButtonArrow className='btn btn-orange--extra rounded-3 fw-600 fs-14 mt-2'>
                  <a
                    href='https://shopping.inter.co/'
                    className='text-white mr-2'
                    target='_blank'
                    rel='noreferrer'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_02',
                        section_name: 'Cashback para todo mundo. Correntista, ou não.',
                        element_action: 'click button',
                        element_name: 'Ir para Inter Shop Web',
                        redirect_url: 'https://shopping.inter.co/',
                      })
                    }}
                  >
                    Ir pro Shopping do Inter
                  </a>
                  <div className='text-right'><OrangeIcon size='MD' color='white' icon='arrow-right' /></div>
                </ButtonArrow>
              </div>
            </div>
          </div>
        </section>
        <WhereToEarnCashbackSection ref={whereToEarnRef}>
          <div className='container pb-5'>
            <div className='row align-items-center'>
              <animated.div style={animatedWhereToEarn[0]} className='col-12 col-md-6 col-xl-5 offset-xl-1'>
                <span className='fs-12 fs-lg-14 lh-15 lh-lg-17 text-grayscale--300 text-uppercase fw-600'>Produtos e serviços</span>
                <h2 className='fs-40 fs-lg-64 text-grayscale--500 fw-300'>Onde ganhar cashback?</h2>
                <animated.div style={animatedWhereToEarn[1]} className='col-12 col-md-6 col-xl-3 offset-xl-1 d-md-none'>
                  <ImgSizeWhereToEarnCashback>
                    <Img fluid={images[imageNames[imageIndex]].fluid} />
                  </ImgSizeWhereToEarnCashback>
                </animated.div>
                <div className='d-flex justify-content-start icon-wrapper'>
                  <div className='opacity d-none d-md-block'><img src={imgOpacity} /></div>
                  {WhereToEarnCashbackJSON.map((item: WhereToEarnCashbackProps, index: number) =>
                    (
                      <div
                        key={index}
                        className={`${iconIndex === index ? 'current-icon' : 'another-icon'} ${iconIndex > 4 && isMobile ? 'margin-icon' : 'margin-icon-none'} icons d-flex align-items-center`}
                      >
                        <OrangeIcon
                          size='MD'
                          color={iconIndex === index ? 'white' : 'black'}
                          icon={item.icon}
                        />
                      </div>
                    ))
                  }
                </div>
                <DefaultCarousel
                  sm={{ items: 1 }}
                  md={{ items: 1 }}
                  lg={{ items: 1 }}
                  xl={{ items: 1 }}
                  beforeChange={(nextSlide: number) => changeImage(nextSlide)}
                >
                  {
                    WhereToEarnCashbackJSON.map((item: WhereToEarnCashbackProps) => (
                      <div key={item.title}>
                        <p className='fs-18 lh-22 text-grayscale--500 fw-600 mt-4 mb-1'>{item.title}</p>
                        <p className='fs-14 lh-17 text-grayscale--400'>{item.description}</p>
                        <p className='fs-14 lh-17 fw-600 mb-4'>
                          <a
                            href={item.url}
                            onClick={() => {
                              sendDatalayerEvent({
                                section: 'dobra_04',
                                section_name: 'Onde ganhar cashback?',
                                element_action: 'click button',
                                element_name: item.cta,
                                redirect_url: item.url,
                              })
                            }}
                          >
                            {item.cta}
                          </a>
                        </p>
                      </div>
                    ))
                  }
                </DefaultCarousel>
              </animated.div>
              <animated.div style={animatedWhereToEarn[1]} className='col-12 col-md-6 col-xl-3 offset-xl-1 d-none d-md-block'>
                <ImgSizeWhereToEarnCashback>
                  <Img fluid={images[imageNames[imageIndex]].fluid} />
                </ImgSizeWhereToEarnCashback>
              </animated.div>
            </div>
          </div>
        </WhereToEarnCashbackSection>
        <CashbackComCartao className='d-md-none'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <ImgSize>
                  <Img className='m-auto' fluid={data.cashbackComSeuCartao.fluid} alt='Cartão Inter' />
                </ImgSize>
              </div>
            </div>
          </div>
        </CashbackComCartao>
        <section ref={interShopRef}>
          <div className='container py-5'>
            <div className='row align-items-center'>
              <animated.div style={animatedInterShop[0]} className='col-12 col-md-6 col-xl-5 offset-xl-1'>
                <span className='fs-12 fs-lg-14 lh-15 lh-lg-17 text-grayscale--300 text-uppercase fw-600'>Inter Shop</span>
                <h2 className='fs-40 fs-lg-64 text-grayscale--500 fw-300'><span className='d-md-block'>Cashback no</span> Inter Shop</h2>
                <p className='fs-16 lh-19 text-grayscale--400'>
                  <span className='d-lg-block'>Tenha mais de 900 lojas ao alcance de um clique e</span> ganhe cashback nas suas compras no Inter Shop.
                </p>
                <LogosInterShop className='d-none d-md-block mt-3 mt-lg-4'>
                  <Img className='m-auto' fluid={data.logosInterShop.fluid} alt='Logos Parceiros Inter Shop' />
                </LogosInterShop>
                <p className='fs-14 lh-19 text-grayscale--500 fw-600 d-inline-block mt-4'>Não tem conta no Inter? Ganhe cashback pelo Inter Shop Web:</p>
                <ButtonArrow className='btn btn-orange--extra rounded-3 fw-600 fs-14 mt-2'>
                  <a
                    href='https://shopping.inter.co/'
                    target='_blank'
                    rel='noreferrer'
                    className='text-white mr-2'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_05',
                        section_name: 'Cashback no Inter Shop',
                        element_action: 'click button',
                        element_name: 'Ir para Inter Shop',
                        redirect_url: 'https://shopping.inter.co/',
                      })
                    }}
                  >
                    Ir para Inter Shop
                  </a>
                  <div className='text-right'><OrangeIcon size='MD' color='white' icon='arrow-right' /></div>
                </ButtonArrow>
                <div className='d-md-none mt-5 mb-2'>
                  <Img className='m-auto' fluid={data.logosInterShop.fluid} alt='Logos Parceiros Inter Shop' />
                </div>
              </animated.div>
              <animated.div style={animatedInterShop[1]} className='col-12 col-md-6'>
                <ImgSizeCashbackInterShop className='mt-3'>
                  <Img className='m-auto' fluid={data.cashbackInterShop.fluid} alt='Controle de vídeo game e smartphone' />
                </ImgSizeCashbackInterShop>
              </animated.div>
            </div>
          </div>
        </section>
        <section ref={interInvestRef}>
          <div className='container pb-5'>
            <div className='row align-items-center'>
              <animated.div style={animatedInvest[1]} className='col-12 col-md-6 col-xl-5 offset-xl-1 order-md-last'>
                <span className='fs-12 fs-lg-14 lh-15 lh-lg-17 text-grayscale--300 text-uppercase fw-600'>Inter Invest</span>
                <h2 className='fs-40 fs-lg-64 text-grayscale--500 fw-300'>Invista e ganhe Cashback em Pontos Loop</h2>
                <p className='fs-16 lh-19 text-grayscale--400'>
                  <span className='d-xl-block'>Clientes Black e WIN, ao investir em fundos que têm </span>
                  <span className='d-xl-block'>o selo de cashback, podem receber uma parte </span>
                  <span className='d-xl-block'>da receita de distribuição de volta em sua conta. <span className='position-relative cursor-pointer'><ToolTip /></span></span>
                </p>
                <a
                  onClick={() => {
                    openModalAndSendDatalayer({
                      section: 'dobra_06',
                      element_action: 'click scroll',
                      element_name: 'Abrir minha conta no Inter',
                      section_name: 'Invista e ganhe cashback',
                    })
                  }}
                >
                  <ButtonArrow className='btn btn-orange--extra text-white rounded-3 fw-600 fs-14 mt-2'>
                    Abrir minha conta no Inter
                    <div className='text-right ml-2'><OrangeIcon size='MD' color='white' icon='arrow-right' /></div>
                  </ButtonArrow>
                </a>
              </animated.div>
              <animated.div style={animatedInvest[0]} className='col-12 col-md-6 mt-4 invistaGanheImgDiv'>
                <ImgSizeInvistaGanhe>
                  <Img className='m-auto' fluid={data.invistaGanhe.fluid} alt='Gráfico de investimentos' />
                </ImgSizeInvistaGanhe>
              </animated.div>
            </div>
          </div>
        </section>
        <section className='bg-gray'>
          <div className='container py-5'>
            <div className='row'>
              <div className='col-12 col-md-6 col-xl-5 offset-xl-1'>
                <span className='fs-12 fs-lg-14 lh-15 lh-lg-17 text-grayscale--300 text-uppercase fw-600'>Simulador</span>
                <h2 className='fs-40 fs-lg-64 text-grayscale--500 fw-300'>Calcule seu cashback</h2>
                <p className='fs-16 lh-19 text-grayscale--400'>
                  No Inter, você recebe dinheiro de volta em vários serviços. Os cashbacks chegam até 20%! Faça a simulação ao lado de quanto cashback você pode ganhar nos principais serviços do Inter.
                </p>
                <p className='fs-16 lh-19 text-grayscale--500 fw-600'>Quer ganhar cashback? Abra sua conta no Inter!</p>
                <a
                  onClick={() => {
                    openModalAndSendDatalayer({
                      section: 'dobra_07',
                      section_name: 'Calcule seu cashback',
                      element_action: 'click scroll',
                      element_name: 'Abrir minha conta no Inter',
                    })
                  }}
                >
                  <ButtonArrow className='btn btn-orange--extra text-white rounded-3 fw-600 fs-14 mt-2'>
                    Abrir minha conta no Inter
                    <div className='text-right ml-2'><OrangeIcon size='MD' color='white' icon='arrow-right' /></div>
                  </ButtonArrow>
                </a>
              </div>
              <div className='col-12 col-md-6 cashbackSimulatorDiv'>
                <CashbackSimulator />
              </div>
            </div>
          </div>
        </section>
        <section ref={cardRef}>
          <div className='container pt-5 py-xl-5'>
            <div className='row align-items-center'>
              <animated.div style={animatedCard[1]} className='col-12 col-md-6 col-xl-5 offset-xl-1 order-md-last'>
                <span className='fs-12 fs-lg-14 lh-15 lh-lg-17 text-grayscale--300 text-uppercase fw-600'>Extrato de cashback</span>
                <h2 className='fs-40 fs-lg-64 text-grayscale--500 fw-300'>Acompanhe seu cashback</h2>
                <p className='fs-16 lh-19 text-grayscale--400'>
                  No app do Inter, você pode ver todas as transações em que você recebeu dinheiro de volta no <span className='fw-600'>Extrato de Cashback.</span> Para acessar, siga os passos:
                </p>
                <VideoDiv className='col-12 col-md-6 d-md-none'>{videoElement}</VideoDiv>
                <div className='d-flex justify-content-start'>
                  <div className='pr-3'><p className='fs-18 lh-22 text-grayscale--500 fw-600'>1.</p></div>
                  <div><p className='fs-14 lh-17 text-grayscale--400'>Faça login no app e vá até a home.</p></div>
                </div>
                <div className='d-flex justify-content-start'>
                  <div className='pr-3'><p className='fs-18 lh-22 text-grayscale--500 fw-600'>2.</p></div>
                  <div><p className='fs-14 lh-17 text-grayscale--400'>Role os cards até o card com o título “Extrato de Cashback”.</p></div>
                </div>
                <div className='d-flex justify-content-start'>
                  <div className='pr-3'><p className='fs-18 lh-22 text-grayscale--500 fw-600'>3.</p></div>
                  <div><p className='fs-14 lh-17 text-grayscale--400'>Toque no card e veja todas as vezes que você ganhou cashback</p></div>
                </div>
                <a
                  onClick={() => {
                    openModalAndSendDatalayer({
                      section: 'dobra_08',
                      section_name: 'Acompanhe seu cashback',
                      element_action: 'click scroll',
                      element_name: 'Abrir conta no Inter',
                    })
                  }}
                >
                  <ButtonArrow className='btn btn-orange--extra text-white rounded-3 fw-600 fs-14 mt-2'>
                    Abrir conta no Inter
                    <div className='text-right ml-2'><OrangeIcon size='MD' color='white' icon='arrow-right' /></div>
                  </ButtonArrow>
                </a>
              </animated.div>
              <animated.div style={animatedCard[0]} className='col-12 col-md-6 mt-4 d-none d-md-block'>
                <VideoDiv>{videoElement}</VideoDiv>
              </animated.div>
            </div>
          </div>
        </section>
        <section id='dicas-para-investir-melhor' className='bg-white d-flex pb-md-5 pb-xl-0'>
          <div className='container pb-5'>
            <div className='row'>
              <div className='col-12 carousel-div'>
                <DefaultCarousel
                  sm={{ items: 1 }}
                  md={{ items: 2 }}
                  lg={{ items: 3 }}
                  xl={{ items: 3 }}
                >
                  { BlogArticlesCashbacksJSON.map((item: BlogArticlesCashbacksProps, index: number) => (
                    <BlogCarousel key={index} className='col-12 p-card mt-md-3'>
                      <article className='col-12 px-0'>
                        <Img fluid={data[item.image].fluid} alt={item.alt} />
                        <div className='col-12 pt-3 rounded-5 px-4 mt-n5 bg-white'>
                          <p className='fs-12 lh-15 fw-600 text-white bg-orange--extra rounded-1 mb-1 px-2 mr-2 primary-tag'>{item.primaryTag}</p>
                          <p className='fs-12 lh-15 fw-600 text-orange--extra bg-gray-400 rounded-1 mb-1 px-2 secundary-tag'>{item.secundaryTag}</p>
                          <p className='fs-20 lh-25 fw-600 mt-3 mb-2 mt-md-2 mt-xl-2 title-post text-grayscale--500'>{item.title}</p>
                          <span className='fs-12 lh-15 fw-400 d-block text-grayscale--300'>{item.data}</span>
                          <p className='fs-14 lh-19 lh-md-19 mb-2 fw-400 d-none d-lg-block mt-3 text-grayscale--400'>{item.description}</p>
                          <a
                            data-blog={item.title}
                            href={item.link}
                            title={`Leia mais em ${item.link}`}
                            className='fs-14 lh-17 fw-400 text-orange--extra text-right d-none d-lg-block mt-xl-3'
                            target='_blank'
                            rel='noreferrer'
                            onClick={() => {
                              sendDatalayerEvent({
                                section: 'dobra_09',
                                section_name: 'null',
                                element_action: 'click banner',
                                element_name: item.title,
                              })
                            }}
                          >
                            Leia mais
                            <OrangeIcon size='MD' color='#FF9D42' icon='arrow-right' />
                          </a>
                        </div>
                      </article>
                    </BlogCarousel>
                    ))
                    }
                </DefaultCarousel>
              </div>
            </div>
          </div>
        </section>
        <PerguntasFrequentes id='perguntas-frequentes' className='py-5'>
          <div className='container'>
            <div className='row'>
              <div className='mb-4'>
                <h3 className='fs-32 fw-500 text-grayscale--500 sora'>Perguntas frequentes</h3>
              </div>
              <Faq id='cashbacks' columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} className='px-0' answerData={pageContext.structuredData.faq} />
              <div className='col-12 my-4 px-0'>
                <p className='fs-14 lh-17 text-grayscale--500'>
                  Se quiser saber mais, acesse nossa
                  <a
                    className='text-orange--base'
                    href='https://ajuda.bancointer.com.br'
                    target='_blank'
                    rel='noreferrer'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_10',
                        section_name: 'Perguntas frequentes',
                        element_action: 'click button',
                        element_name: 'Central de Ajuda para outras perguntas.',
                      })
                    }}
                  ><u> Central de Ajuda </u>
                  </a>para outras perguntas.
                </p>
              </div>
            </div>
          </div>
        </PerguntasFrequentes>
        <section className='d-none'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <video id='introVideo' height='auto' width='100%'>
                  <source id='videoMp4' type='video/mp4' src='videos_18.09.2020/banner/pt/RI-1396x424-compressed.mp4?v=1' />
                  <source id='videoWebm' type='video/webm' src='videos_18.09.2020/banner/pt/RI-1396x424-compressed.webm?v=1' />
                  <source id='videoOgg' type='video/ogg' src='videos_18.09.2020/banner/pt/RI-1396x424-compressed.ogv?v=1' />
                </video>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </Wrapper>
  )
}

export default Cashbacks
