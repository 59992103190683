import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { white, grayscale, orange } from 'src/styles/colors'
import { rounded } from 'src/styles/rounded'

export const BoxSimulator = styled.div`
  background-color: ${white};
  border-radius: ${rounded['10']};
  box-shadow: 0px 0px 40px rgba(60, 112, 190, 0.08);
  float: right;

  @media ${device.tablet} {
    width: 334px;
  }
  @media ${device.desktopLG} {
    width: 455px;
  }

  input[type='text'] {
    border: none;
    width: 100%;
    color: ${grayscale['500']};
    border-bottom: 1px solid ${grayscale['200']};
    outline: none;
    padding-left: 50px;
    font-family: 'Sora';
  }

  /* Firefox */
  input[type=range]::-moz-range-track {
    background: ${orange.extra};
    height: 1px;
  }
  input[type='range'] {
    width: 200px;
    @media ${device.tablet} {
      width: 224px;
    }
    @media ${device.desktopLG} {
      width: 340px;
    }
    @media ${device.desktopXL} {
      width: 342px;
    }
  }

  .slider {
      -webkit-appearance: none; // não tirar
      height: 1px;
      border-radius: 5px;
      background: ${orange.extra};
      outline: none;
      -webkit-transition: .2s;
      transition: opacity .2s;
  }

  .slider:hover {
    opacity: 1;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: ${white};
    border: 2px solid ${orange.extra};
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: ${white};
    border: 2px solid ${orange.extra};
    cursor: pointer;
  }

  .button {
    top: 11px;
    float: right;
    border: none;
    position: absolute;
    color: ${orange.extra};
    background: transparent;
    font-size: 40px;
    font-weight: 100;
    outline: none;

    @media ${device.tablet} {
      top: 22px;
      position: absolute;
    }
    
    &-right {
      right: 13px;
      @media ${device.tablet} {
        right: 20px;
      }
      @media ${device.desktopLG} {
        right: 30px;
      }
    }
    &-left {
      right: 55px;
      @media ${device.tablet} {
        right: 62px;
      }
      @media ${device.desktopLG} {
        right: 72px;
      }
    }
  }

  .cifra {
    display: block;
    font-size: 32px;
    font-weight: normal;
    position: absolute;
  }
  span {
    font-family: 'Sora';
  }
`

export const Hr = styled.hr`
  border: 1px dashed ${grayscale['200']};
`
