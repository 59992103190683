import React, { useState, useRef, ChangeEvent, useEffect } from 'react'
import { BoxSimulator, Hr } from './style'

function currencyToFloat (currencyNumber: string) {
  const result = currencyNumber === '' ? 0 : parseInt(currencyNumber.replace(/\D/g, '')) / 100
  return result
}

function floatToCurrency (floatNumber: number) {
  return floatNumber.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

const CashbackSimulator = () => {
  const [ resultadoSimulacao, setResultadoSimulacao ] = useState<string>('')
  const [ amountSpent, setAmountSpent ] = useState<number>(0)
  const [ percentage, setPercentage ] = useState<number>(0)
  const [ errorMessage, setErrorMessage ] = useState<boolean>(false)

  const inputTotalValueRef = useRef<HTMLInputElement>(null)

  const percentageCalculation = () => {
    setResultadoSimulacao(floatToCurrency((percentage / 100) * amountSpent))
  }

  useEffect(() => {
    percentageCalculation()
  }, [ percentage, amountSpent ])

  const error = () => {
    setErrorMessage(true)
    if (inputTotalValueRef.current) {
      inputTotalValueRef.current.focus()
     }
  }

  const changeTotalValue = (evt: ChangeEvent<HTMLInputElement>) => {
    const amountSpentFormated = currencyToFloat(evt.target.value)
      setAmountSpent(amountSpentFormated)
      setErrorMessage(false)
    }

  const changeRange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (amountSpent === 0) {
      error()
    } else {
      setPercentage(parseInt(evt.target.value))
    }
  }

  const add = () => {
    if (amountSpent === 0) {
      error()
    } else {
      setAmountSpent(amountSpent + 10)
    }
  }

  const subtract = () => {
    if (amountSpent === 0) {
      error()
    } else {
      setAmountSpent(amountSpent - 10)
    }
  }
  return (
    <BoxSimulator className='pt-4 mt-4 mt-md-0'>
      <>
        <div className='px-3 position-relative'>
          <p className='fs-16 fs-lg-18 lh-19 text-grayscale--400 mb-0 mb-md-2'>Comprando ou investindo:</p>
          <span className='cifra mr-2 text-grayscale--500'>R$</span>
          <input
            type='text'
            placeholder='0'
            className='fs-32'
            maxLength={10}
            value={floatToCurrency(amountSpent)}
            ref={inputTotalValueRef}
            onChange={(evt: ChangeEvent<HTMLInputElement>) => changeTotalValue(evt)}
          />
          <button className='button button-left' onClick={subtract}>-</button>
          <button className='button button-right' onClick={add}>+</button>
          <p className={`${errorMessage === true ? 'd-block' : 'd-none'} text-red fs-12 text-red--light position-absolute mb-0`}>Preencha um valor</p>
        </div>
        <div className='px-3 mt-4'>
          <p className='fs-16 fs-lg-18 lh-19 text-grayscale--400 mb-0'>Com porcentagem de cashback de:</p>
          <div className='d-flex justify-content-between align-items-center'>
            <div>
              <input
                type='range'
                min={0}
                max={20}
                step={1}
                className='slider'
                value={percentage}
                onChange={(evt: ChangeEvent<HTMLInputElement>) => changeRange(evt)}
              />
            </div>
            <div>
              <span className='fs-32 text-grayscale--500'>{percentage}%</span>
            </div>
          </div>
        </div>
        <Hr />
        <div className='px-3 pt-2'>
          <p className='fs-16 fs-lg-18 lh-19 mb-0 text-grayscale--500'>Você receberá cashback de:</p>
          <p className='fs-40 fs-lg-56 fw-600'>
            <span className='text-gray--200 fw-300'>R$</span> <span className='text-orange--extra fw-300'>{resultadoSimulacao}</span>
          </p>
        </div>
      </>
    </BoxSimulator>
  )
}

export default CashbackSimulator
